import React from 'react';

const User = () => {
        
    var source321
    let idRegex = new RegExp("source321=([^;]+)")
    try {source321 = idRegex.exec(document.cookie)[1]}
    catch (err) {source321=null}

    var referrer321
    let idRegex2 = new RegExp("referrer321=([^;]+)")
    try {referrer321 = idRegex2.exec(document.cookie)[1]}
    catch (err) {referrer321=null}

    var source_code321
    let idRegex3 = new RegExp("source_code321=([^;]+)")
    try {source_code321 = idRegex3.exec(document.cookie)[1]}
    catch (err) {source_code321=351}

    return ( 
        <div id="hidden_fields">
            <input type="hidden" name="source" value={source321||'null'} />
            <input type="hidden" name="referrer" value={referrer321||'null'} />
            <input type="hidden" name="source_code" value={source_code321||'null'} />
        </div>
     );
}
 

export default User;