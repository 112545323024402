import React from 'react';
import { Helmet } from 'react-helmet';
import ContactUs from '../components/ContactUs';
import '../styles/321global.css';
import '../styles/launchpad.css';
import '../styles/circles.css';
import '../styles/nav.css';

const ContactPage = () => {
    return (
        <div id="contact">
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content="Contact Us"></meta>
            </Helmet>
            <ContactUs />
        </div>
    )

}

export default ContactPage;