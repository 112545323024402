import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Session321 from './Session321';
import logo from '../img/logo-word-mark.svg';
import RocketPhone from '../img/rocket_to_phone/rocket-to-phone-bluebg.gif';
import RocketPhone2 from '../img/rocket_to_phone/rocket-to-phone.gif';
import '../styles/321global.css';
import '../styles/nav.css';
import '../styles/contact-us.css';
import  '../styles/circles.css';

export default function ContactUs (props)  {
return (
    <div>
        <div id="media_home">
            <Session321 />
            <div id="contact_hero_left">
                <div className="hero-top">
                    <div id="logo"><img src={logo} alt="menu icon" height="40px" width="180px"/></div>
                </div>
                <div className="hero-bottom">
                    <div className="hero-content">
                        <div id="hero_headline"><h1>Welcome.</h1></div>
                        <div id="hero_subhead">
                            <h3 className="hero-subheader">
                                Thanks for stopping by.
                                <br /><br />
                                We love getting questions and feedback from the businesses we help. 
                                Use the contact form to get in touch, and a member of our team will reach out as soon as possible.
                                We’re looking forward to hearing from you.
                            </h3>
                        </div>
                    </div>
                    <div className="circle-container">
                        <div className="circle">
                            <div className="circle">
                                <div className="circle">
                                    <div className="circle">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contact_hero_right">
                <div className="hero-top">
                    <a href="tel:+1-234-230-6321">
                        <div id="call_div">
                            <div id="phone_number"><h5>Call <span className="bold">(234) 230-6321</span> for a free quote</h5></div>
                            <div id="call_icon"><img src={RocketPhone} alt="rocketphone" /></div>
                        </div>
                    </a>
                </div>
                <div className="mobile-hero-top">
                    <a href="tel:+1-234-230-6321">
                        <div id="call_div">
                            <div id="phone_number"><span className="bold">(234) 230-6321</span><img src={RocketPhone} alt="rocketphone" /></div>
                            {/* <div id="call_icon"><img src={RocketPhone2} alt="rocketphone" /></div> */}
                        </div>
                    </a>
                </div>
                <div className="hero-form">
                    <ContactForm />
                </div>
            </div>
        </div> 
        <Footer />
    </div>
)

}









