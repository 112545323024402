import React from 'react';
import User from './User';

export default function ContactForm (props) {
    return (
        <div id="contact_form">
            <form acceptCharset="UTF-8" action="https://getform.io/f/e6261ae0-8975-4980-8758-d3f25c9f9909" method="POST">
                <input type="text"  name="first_name" placeholder="First Name" required></input>
                <input type="text" name="last_name" placeholder="Last Name" required></input>
                <input type="email" name="email" placeholder="Email" required></input>
                <input type="tel" name="callback_number" placeholder="Callback Number" pattern="\(?\d{3}\)?-? *\d{3}-? *-?\d{4}" maxLength={14} minLength={10} required></input>
                <textarea name="message" placeholder="Your Message" rows="10" required></textarea>
                <p className="form-pref">Response Preference</p>
                <input type="radio" name="preference" id="phone-pref" value="phone"></input>
                <label for="phone-pref">Phone</label>
                <input type="radio" name="preference" id="email-pref" value="email"></input>
                <label for="email-pref">Email</label>
                <User />
                <div className="button-container">
                    <button className="contact-submit" type="submit">Send Message</button>
                </div>
            </form>
        </div>
    )
}